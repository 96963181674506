<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <!--    Form Dialog-->
    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <v-card class="mx-auto">
        <v-toolbar
          flat
        >
          <v-toolbar-title class="grey--text">
            <span class="text-h3">{{ formTitle }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="close"
          >
            <v-icon class="red--text">
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.name"
                  placeholder="Class Category Name"
                  outlined
                  label="Class Category Name"
                  @keyup.enter="onAddClassCategory"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loader"
            color="primary"
            @click="onAddClassCategory"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Form Dialog End-->
    <!--    Delete Dialog Start-->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this client?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Delete Dialog end-->

    <!--    All Dialogs End-->
    <!--    Base Material Start -->
    <div v-if="fetchup">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <base-material-card
      v-else
      color="primary"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-row class="align-content-md-space-between">
            <v-col
              cols="12"
              md="9"
              sm="6"
            >
              <div class="text-h3 font-weight-light">
                <v-icon
                  left
                  class="primary--text"
                >
                  mdi-animation
                </v-icon> Class Categories <i class="text-caption">Total: <b>{{ total }}</b></i>
              </div>

              <div class="text-subtitle-1 font-weight-light">
                Please manage all class categories
              </div>
            </v-col>
            <v-col
              md="3"
              sm="6"
              style="text-align:right"
            >
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2 subheading font-weight-bold"
                @click="openDialog"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add Class Category
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>

      <v-data-table
        :headers="headers"
        :items="ccategories"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            text
            class="grey--text"
            @click="editItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
          <v-btn
            small
            text
            class="red--text"
            @click="deleteItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            Delete
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn
            small
            color="primary"
            @click="fetchClassCategories"
          >
            <v-icon left>
              mdi-refresh
            </v-icon>Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
    <!--    Base Material End-->
  </v-container>
</template>
<script>
  /* eslint-disable */
  const axios = require('axios')
  export default {
    name: 'ManageClassCategories',
    data: () => ({
      menu: false,
      dialog: false,
      fetchup: false,
      dialogDelete: false,
      loader:false,
      err:[],
      headers: [
        {
          text: 'Class Category Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      ccategories: [],
      editedIndex: -1,
      editedItem: {
        name: '',
      },
      defaultItem: {
        name: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Add New Class Category' : 'Edit Class Category'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
      this.fetchClassCategories()
    },

    methods: {
      openDialog(){
        this.dialog = true;
      },
      fetchClassCategories () {
        this.fetchup = true
        const self = this
        axios.get(this.apiSet.classCategory)
          .then(response => {
            const obj = response.data.categories
            self.ccategories = obj
            self.total = obj.length
            self.fetchup = false
            console.log({message: obj});
            // self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
          }).catch(
          e => {
            self.fetchup = false
            this.err.push(e)
          },
        )
      },
      onAddClassCategory() {
        if (this.editedIndex > -1) {
          this.updateClassCategory()
        } else {
          const self = this
          // this.$validator.validateAll().then((result) => {
          //   if (result) {
          this.loader = true
          const catData = this.editedItem

          axios.post(this.apiSet.classCategory, {
            name: catData.name,
          })
            .then(function (response) {
              self.close()
              self.fetchClassCategories()
              self.loader = false
              this.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
            })
            .catch(error => {
              self.loader = false
              self.err.push(error)
            })
          // } else {
          //   self.snack = true
          //   self.snackText = 'Error. Please correct form errors.'
          //   self.snackColor = 'red'
          //   return false
          // }
          // })
        }
      },

      updateClassCategory () {
        const self = this
        this.loader = true
        const catData = this.editedItem

        axios.put(this.apiSet.classCategory+'/'+this.editedItem._id,
          {
            name: catData.name,
          })
          .then(function (response) {
            self.close()
            self.fetchClassCategories()
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully updated.', c: 'green darken-3' })
          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      editItem (item) {
        this.editedIndex = this.ccategories.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ccategories.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.ccategories.splice(this.editedIndex, 1)
        const self = this
        this.loader = true
        const delId = this.editedItem._id

        axios.delete(this.apiSet.classCategory+'/'+delId)
          .then(function (response) {
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully deleted.', c: 'green darken-3' })
            self.closeDelete()

          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.ccategories[this.editedIndex], this.editedItem)
        } else {
          this.ccategories.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>

